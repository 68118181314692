@import "../../styles/base.scss";

$header-height: 60px;
$header-width: 100%;

.main-header {
  width: $header-width;
  box-shadow: 1px 1px 4px $gray;
  .navbar {
    padding: 10px;
    box-shadow: 0px 4px 4px $gray;
    .navbar-nav {
      height: 100vh;
      overflow: scroll;
      @include media(md-desktop) {
        height: unset;
        overflow: auto;
      }
      .menu-item {
        padding: 0.9rem 1rem;
      }
      .nav-link {
        color: rgba(0, 0, 0, 0.7);
        font-size: 15px;
        h5.hoverRed:hover{
          color: $primary;
        }
        &:hover {
          color: $primary;
          h5 {
            color: $black;
          }
          
          .navbar-content {
            display: block;
          }
          
        }
        .navbar-content {
          display: none;
          position: relative;
          @include media(md-desktop) {
            display: none;
            position: absolute;
            width: 97%;
            left: 0;
            right: 0;
            top: 60px;
            margin: 0px 15px;
            z-index: 1;
          }
          .navbar-content-list {
            overflow: auto;
            height: 90vh;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none;
            margin-top: 10px;
            background-color: #f9f9f9;
            box-shadow: 0px 1px 6px 3px $gray;
            @include media(md-desktop) {
              margin: 0px;
              box-shadow: 0px 1px 6px 3px $gray;
            }
            .navbar-col {
              padding: 16px;
              border-top: 1px solid $gray;
              &:not(:last-child) {
                border-right: 1px solid $gray;
              }
            }
            .nav-link {
              &:hover svg {
                display: inline-block;
              }
              svg {
                display: none;
              }
            }
            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }
    }
    .btn.btn-primary:hover {
      background-color: $secondary;
      border-color: $secondary;
    }
  }
}
.main-header img{
  cursor:pointer;
}
// .menu-selected-item {
//   color: #D52D34 !important;
// }