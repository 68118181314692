@font-face {
  font-family: "Poppins-Medium";
  src: url("../../fonts/Poppins/Poppins-Medium.ttf");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("../../fonts/Poppins/Poppins-Bold.ttf");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Poppins-Thin";
  src: url("../../fonts/Poppins/Poppins-Thin.ttf");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("../../fonts/Poppins/Poppins-Regular.ttf");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Poppins-Light";
  src: url("../../fonts/Poppins/Poppins-Light.ttf");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Poppins-Black";
  src: url("../../fonts/Poppins/Poppins-Black.ttf");
  font-style: normal;
  font-weight: 200;
}
