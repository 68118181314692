@import "../../styles/base.scss";

.og-footer {
  background-color: $black;
  padding-top: 40px;

  hr {
    color: $white;
  }

  &-getintouch.card,
  &-navlink,
  &-contact .card,
  &-copyright {
    background-color: unset;
    color: #ffffff;
    border: unset;
  }
  &-getintouch {
    // width: 70%;
    .card-text {
      font-size: 30px;
      @include media(md-desktop) {
        font-size: 60px;
      }
    }
    button {
      border-radius: 20px;
    }
  }
  &-navlink {
    justify-content: center;
    gap: 20px;
    .flex-column {
      background-color: $black;
      color: $white;
      margin-bottom: 20px;
      span {
        padding-left: 1rem;
      }
      a {
        color: rgba(255, 255, 255, 0.7);
        padding: 10px 0;
        &:hover {
          color: $primary;
        }
      }
      .footer-title {
        padding: 15px 15px 15px 0;
        border-bottom: 1px solid $gray;
        margin: 0;
        position: relative;
        font-weight: normal;
        text-transform: uppercase;
        font-weight: 600;
        &:after {
          content: "";
          cursor: pointer;
          position: absolute;
          top: 25px;
          right: 10px;
          border: solid $white;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 4px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
        &.footer-active:after {
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
        }
        @include media(md-desktop) {
          &:after {
            display: none;
          }
          border-bottom: 0;
        }
      }
      .footer-menu {
        display: none;
        &.footer-wrap {
          display: block;
        }
        @include media(md-desktop) {
          display: block;
          position: relative;
          z-index: 1;
        }
      }
    }
  }
  &-contact {
    .list-group {
      .list-group-item {
        background-color: $black;
        border: 1px solid $black;
        color: rgba(255, 255, 255, 0.7);
        padding: 0.5rem 0;
        svg {
          width: 15px;
        }
      }
    }
    .img-section {
      padding: 0;
      .card-img-top {
        width: 150px;
        
      }
      p {
        font-size: 10px;
        color: rgba(255, 255, 255, 0.7);
      }
    }
    .contact-section,
    .off-section {
      padding: 0;
      // margin-top: 15px;
    }
    .contact-title {
      padding: 15px 15px 15px 0;
      border-bottom: 1px solid $gray;
      margin: 0;
      position: relative;
      font-weight: normal;
      text-transform: uppercase;
      font-weight: 600;
      &:after {
        content: "";
        cursor: pointer;
        position: absolute;
        top: 25px;
        right: 10px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
      &.contact-active:after {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
      @include media(md-desktop) {
        &:after {
          display: none;
        }
        border-bottom: 0;
        padding: 0;
      }
    }
    .contact-menu {
      display: none;
      &.contact-wrap {
        display: block;
      }
      @include media(md-desktop) {
        display: block;
        position: relative;
        z-index: 1;
      }
    }
  }
  &-copyright {
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
  }
}

.aboutusFooter{
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #FFFFFF !important;

}
.aboutusFooter:hover {
  color: #aa242a !important;
}

.getIntouchBtn{
  margin:88px 40px 80px 152px !important;
  height: 59px !important;
  width: 175px !important;
  border-radius: 40px !important;
}

@media screen and (max-device-width:640px), screen and (max-width:640px) {
  .getIntouchBtn{
    margin:11px 0px 0px 0px !important;
  }
  .img-section{
    padding: 0 24px !important;
  }
}