.approach{
    margin-left: 40px;
    margin-right: 40px;
}
.imagecontent {
    position: relative;
    text-align: center;
  }
  
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .center b{
   font-size: 23px;
  }
.approachaboutnew2 img{
    float: right;
}
.approachnew1 h3{
    font-size: 40px;
    font-weight: 600;
    margin-top: 50px;
    text-align: center;
}
.approachnew1 p{
    font-weight: 400;
    text-align: center;
}
.approachheader{
    margin-top: 60px;
}
.approachheader p{
    font-weight: 600;
    margin-top: 25px;
}
.approachheader img{
    margin-top: 25px;
}
.approach1{
    margin-top: 80px;
    
}
.approachsection2 img{
   float: right;
   
}
.approachsection1 h3{
    margin-top: 80px;
    margin-left: 140px;
    font-size: 40px;
    font-weight: 600;
}
.approachsection1 h5{
    margin-top: 40px;
    margin-left: 140px;
    font-size: 25px;
    font-weight: 400;
}
.approachsection1 p{
    margin-top: 30px;
    margin-left: 140px;
}
.approachheader1 p{
    font-weight: 800;
    margin-left: 140px;
}
.approachsection3 img{
    float: left;
}
.approachsection4 h3{
    margin-top: 80px;
   
    font-size: 40px;
    font-weight: 600;
    margin-right: 10px;
}
.approachsection4 h5{
    margin-top: 40px;
  
    font-size: 25px;
    font-weight: 400;
}
.approachsection4 p{
    margin-top: 30px;
 
    margin-right: 10px;
}
.approachheader4 p{
    font-weight: 800;
  
    margin-right: 10px;
}
.approachheader2 p{
    font-weight: 800;
}
.approachheader1{
    width: 80%;
}

@media screen and (max-width: 640px) {
    .approachnew1 h3 {
        margin-top: 0px;
    }
    .approach1 {
        margin-top: 0px;
    }
    .approachsection1 h3{
       text-align: center;
        margin-left: 0px;
    }
    .approachsection1 h5{
        text-align: center;
         margin-left: 0px;
     }
     .approachsection1 p{
        text-align: center;
         margin-left: 0px;
     }
     .approachheader1 {
        width: 100%;
    }
    .approachsection4 h3 {
        text-align: center;
    }
    .approachsection4 h5 {
        text-align: center;
    }
    .approachsection4 p{
        text-align: center;
    }
    .approachsection2 img {
        height: 250px;
    }
    .approachsection3 img {
        height: 250px;
    }
}