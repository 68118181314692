.carrer{
    margin-top: 200px;
}
.carrernew2 img{
    width: 450px;
    height: 350px;
    display: block;
    margin-left: auto;
    margin-right: auto;
   
  }
  
  .carrernew1 h4{
      font-weight: 600;
      color:#D52D34;
      margin-left: 80px;
  }
  .carrernew1 h1{
    font-weight: 900;
    margin-left: 80px;
    line-height: 70px;
    font-size: 50px; 
}
.carrernew1 img{
    width: 50px;
}

.carrernew1 span{
    color:#D52D34;
}
.carrernew1 p{
    margin-left: 80px;
    font-weight: 500; 
}
.carrernew1 Button{
    width: 150px;
    height: 40px;
    margin-left: 90px;
}
.join{
    margin-top: 170px;
    margin-left: 70px;
}
.join img{
    width: 50px;
}
.join h5{
    color:#D52D34;
    font-weight: 700;
}
.joincontent1 span{
    color:#D52D34;
}
.joincontent1 h2{
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
}
.joincontent2 p{
    font-weight: 500; 
    text-align: justify;
    margin-right: 70px;
}
.vacancy{
    margin-top: 180px;
    margin-left: 70px;
    margin-right: 70px;
}
.vacancy img{
    width: 50px;
}
.vacancy h5{
    color:#D52D34;
    font-weight: 700;
}
.vacancy h2{
    font-size: 40px;
    font-weight: 700;
}
.vacancycontent2 p{
    font-weight: 500; 
    text-align: justify;
    margin-right: 70px;
}

.developer h4{
    font-weight: 700;
}
.developer {
    margin-top: 10px;
}
.text ul{
    list-style-type:disc;
    margin-left: 50px;
    margin-top: 20px;
}
.text{
    margin-top: 50px;
}
.side{
    display: inline-block;
    text-align: right;
}
.viewbtn{
    margin-left:30px;
    background-color:#fff;
     color:#000;
}
@media screen and (max-width: 640px) {
    .carrer {
        margin-top: 0px;
    }
    .carrernew1 h4 {
        margin-left: 10px;
      
    }
    .carrernew1 Button {
        margin-left: 20px;
    }
    .carrernew1 h1 {
        margin-left: 10px;
    }
    .carrernew1 p {
        margin-left: 8px;
    }
    .carrernew2 img {
        width: 340px;
        margin-top: 40px;
    }
    
    .join {
        margin-top: 60px;
        margin-left: 20px;
        margin-right: -45px;
    }
    .side {
        text-align: center;
        margin-top: 20px;
    }
    .side Button{
        margin-top: 20px;
    }
    .vacancy {
        margin-top: 60px;
        margin-left: 20px;
        margin-right: 20px;
    }
    /* .mo-toggle-menu-mobile .humberger {
        margin-right: 30px;
    } */
}