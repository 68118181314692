.about{
    margin-top: 200px;
}
.aboutnew1 img{
    width: 50px;
}
.missioncontent1 img{
    width: 50px;
}
.visioncontent1 img{
    width: 50px;
}
.valuescontent1 img{
    width: 50px;
}
.aboutnew2 img{
    width: 450px;
    height: 350px;
    display: block;
    margin-left: auto;
    margin-right: auto;
   
  }
  
  .aboutnew1 h4{
      font-weight: 600;
      color:#D52D34;
      margin-left: 80px;
  }
  .aboutnew1 h1{
    font-weight: 900;
    margin-left: 80px;
    line-height: 70px;
    font-size: 50px; 
}
.aboutnew1 span{
    color:#D52D34;
}
.aboutnew1 p{
    margin-left: 80px;
    font-weight: 500; 
}
.aboutnew1 Button{
    width: 150px;
    height: 40px;
    margin-left: 90px;
}
.mission{
    margin-top: 170px;
    margin-left: 70px;
}
.missioncontent1 h5{
    color:#D52D34;
    font-weight: 700;
}
.missioncontent1 span{
    color:#D52D34;
}
.missioncontent1 h2{
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
}
.missioncontent2 p{
    font-weight: 500; 
    text-align: justify;
    margin-right: 70px;
}
.vision{
    margin-top: 120px;
    margin-left: 70px;
}
.visioncontent1 h5{
    color:#D52D34;
    font-weight: 700;
}
.visioncontent1 span{
    color:#D52D34;
}
.visioncontent1 h2{
    font-size: 40px;
    font-weight: 700;
}
.visioncontent2 p{
    font-weight: 500; 
    text-align: justify;
    margin-right: 70px;
}
.values{
    margin-top: 120px;
    margin-left: 70px;
}
.valuescontent1 h5{
    color:#D52D34;
    font-weight: 700;
}
.valuescontent1 span{
    color:#D52D34;
}
.valuescontent1 h2{
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
}
.valuescontent2 p{
    font-weight: 500; 
    text-align: justify;
    margin-right: 70px;
} 
.location{
    margin-top: 120px;
    margin-left: 70px;
    margin-right: 70px;
}
.card-body img{
    width: 25px;
    height: 25px;  
}
.place1 h4{
   
    font-weight: 700;
}
.place1 p{
    margin-top: 30px;
    font-weight: 400;
}
.place1 img{
    margin-top: 10px;
}


.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}
.benefitsCardBox1 {
    min-height: 105px;
    border-radius: 6px !important;
    border: 2px solid rgba(0, 0, 0, 0.03) !important;
    box-shadow: 0px 0px 21px rgb(51 51 51 / 1%) !important;
}
.location h2{
    font-size: 50px;
    font-weight: 600;
    color:#D52D34;
}
.location h3{
    font-weight: 600;
    margin-top: 30px;
}

@media screen and (max-width: 640px) {
    .about {
        margin-top: 0px;
    }
    .aboutnew1 h4 {
        margin-left: 6px;
    }
    .aboutnew1 h1 {
        margin-left: 6px;
    }
    .aboutnew1 p { 
        margin-left: 6px;
    text-align: left;
    }
    .aboutnew2 img {
        width: 325px;
    }
    .mission {
        margin-top: 100px;
    }
    .vision {
        margin-top: 100px; 
    }
    .vision {
        margin-top: 100px; 
    }
    .location {
        margin-top: 100px; 
    }
}
