.mvpheader img{
    width: 100%;
    height: 600px;
    margin-top: -26px;
    position: relative;
    text-align: center;
    color: white;
  } 
  .mvpcentered {
    position: absolute;
    top: 55%;
    left: 27%;
    transform: translate(-16%, -50%);
    
  }
  .mvpcentered h1{
    color:#ffffff;
    text-align: center;
    font-weight: 600;
    font-size: 50px;
    
  }
  .mvpcentered h3{
    color:#ffffff;
    text-align: center;
    font-weight: 600;
    line-height: 47px;
    font-size: 25px;
  }
  .mvpcentered h5{
    color:#ffffff;
    text-align: center;
    font-weight: 200;
    line-height: 30px;
    font-size: 18px;
  }
  .mvpcentered Button{
    line-height: 30px;
  }
  .process{
    margin-left: 90px;
    margin-right: 90px;
  }
  .development h1{
    margin-top: 80px;
    text-align: center;
    font-weight: 600;
    font-size: 40px;
  }
  .development p{
    text-align: center;
    line-height: 30px;
  }
  .card-body img{
    width:50px;
    height:50px;
  }
  .card-body p{
    text-align: left;
    padding-bottom: 20px;
  }
  .card{
    margin-top: 20px;
  }
 .mvpmarketplace {
  background-image: url(../../images/mvp/mvp.png);
  background-size: 100% 600px;
  margin-top: 50px;
  height: 600px;
  width: 100%;
 }
 .textblock {
  position: absolute;
  bottom: -1225px;
  right: 50px;
  background-color: white;
  color: black;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.textblock h3{
  font-weight: 1000;
  font-size: 26px;
}
.textblock p{
  line-height: 30px;
}
.getintouch{
  background-color: #D52D34;
  height: 200px;
  margin-top: 70px;
}
.details1{
  color:#ffffff;
 text-align: center;

}
.details1 h6{
  font-size: 20px;
  font-weight: 300;
  margin-top: 70px;
}
.details2{
  color:#ffffff;
 text-align: center;
 
}
.details2 h6{
  font-size: 20px;
  font-weight: 300;
  margin-top: 70px;
  margin-right: 50px;
}
.consult img{
  width: 450px;
  height: 35opx;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.consult h2{
  font-weight: 700;
  margin-top: 90px;
    margin-left: 80px;
}
.consult p{
    margin-left: 80px;
}
input[type=text], textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit] {
  background-color: #D52D34;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:100%;
}


.contactform {
  margin-top: 70px;
  margin-right: 80px;
  padding: 20px;
  border-radius: 6px !important;
   border: 2px solid rgba(0, 0, 0, 0.03) !important;
   box-shadow: 0px 0px 21px rgb(51 51 51 / 1%) !important;

}
.contactform .error{
  border-color: red !important;
} 
@media screen and (max-width: 640px) {
  
  .mvpcentered h3 {
    margin-left: 0px;
    margin-right: 0px;
    }
    .mvpcentered h5 {
      margin-left: 0px;
    margin-right: 0px;
    }
    .textblock {
      bottom: -2360px;
    }
    .mvpcentered h1 {
      margin-top: 20px;
    }
    .mvpcentered {
      position: absolute;
      top: 55%;
    left: 18%;
      transform: translate(-16%, -50%);
     }
    
  .mvpheader img {
    height: 780px;
    margin-top: -38px;
  }
  .process {
    margin-left: 20px;
    margin-right: 20px;
  }
  .consult img {
    width: 375px;
  }
  .consult h2 {
    margin-left: 25px;
  }
  .consult p {
    margin-left: 30px;
}
  .contactform {
    margin-right: 20px;
    margin-left: 20px;
  }
  .mvpmarketplace {
    background-image: url(../../images/mvp/mvpmobile.PNG);
    background-size: 100% 600px;
    margin-top: 50px;
    height: 600px;
    width: 100%;
   }
  }