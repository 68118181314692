body {
  margin: 0;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:Poppins;
}
.header span{
  color: #D52D34;

}
.main{
  margin-bottom:450px;
}
.mainFullWidthPage{
  width: 100%;
  height: 850px;
  margin-bottom: 100px;
  margin-top: 100px;
}
.leftSideImageMain img {
  width: 100%;
    max-height: 850px;
    object-fit: fill;
}
.leftSideImageMain{
  float: left;
  width: 20%;
}
.rightSideCaroselMain{
  float: left;
  height: 850px;
  width: 80%;
}
.carouselNames{
  height: 780px !important;
}
.mainFullWidthPage .carousel-slider .control-dots{
  display: none;
}
.carousel.carousel-slider .control-arrow
{
  background-color: #000 !important; 
  opacity: 1 !important;
}
.carousel.carousel-slider .control-arrow{
  color: red !important;
}
.carouselNames{
  // height: 500px;
  // width: 500px;
}
.innersection1 img{
  
  height: 7px;
  padding-right: 450px; 
}
.innersection1 h5{
  font-weight: 600;
}
.innersection1 h1{
    font-weight: 700;
    font-size: 51px;
  }
 
.innersection2 img{
  width: 40px;
  height: 40px;
  padding-right: 462px;  
}
.innersection2 h3{
  font-weight: 600;
  margin-top: 20px; 
}
.innersection2 p{
  width: 70%;
}
.innersection3 img{
  width: 40px;
  height: 40px;
  padding-right: 462px; 
  margin-top: 100px;  
}
.innersection3 h3{
  font-weight: 600;
  margin-top: 20px; 
}
.innersection3 p{
  width: 70%;
}
.innersection4 img{
  width: 40px;
  height: 40px;
  padding-right: 462px;
  margin-top: 100px;  
}
.innersection4 h3{
  font-weight: 600;
  margin-top: 20px; 
}
.innersection4 p{
  width: 70%;
}

.slider1{
  text-align: left;
  margin-left: 50px;
  margin-top:70px;
}
.slider2{
  background-color: #D52D34;
  height: 850px;
  text-align: left;
}

.sliderinner1 img{
  margin-left:70px;
  height: 5px;
  padding-right: 450px; 
}
.sliderinner1 h5{
  margin-left:70px;
  font-weight: 600;
  margin-top:120px;
  color: #fff;
}
.sliderinner1 h1{
  margin-left:70px;
    font-weight: 700;
    font-size: 51px;
    color: #fff;
  }
  .sliderinner1 h3{
    font-weight: 600;
    margin-top: 100px;
    margin-left:70px;
    color: #fff; 
  }
  .sliderinner1 p{
    width: 70%;
    margin-left:70px; 
    color: #fff;
  }
  .sliderinner2{
  border-left: 1px solid #ffffff;
  height: 850px;

}
.sliderdeepinner ul{
  color: #fff;
  margin-top: 200px;
margin-left: 80px;

}
.sliderdeepinner li{
  margin-bottom: 20px;
}
// .sliderdeepinner img{
//   height: 5px;
//   width: 20px;
  
// }
.header{
  width: 100%;
  text-align: center;
  margin-top:100px;
  margin-bottom:100px;
 
}
.header h1{
  margin-top:170px;
  font-weight: 700;
  font-size: 51px;
} 
.header p{
  font-size: 18px;
}
.client img{
  width: 70%;
  height: 40px;
}
.client {
  margin-left: 200px;
}

.work{
  margin-top: 100px;
}

.industries h1{
  font-weight: 700;
  text-align: left;
  margin-left: 70px;
  margin-top: 70px;
font-size: 61px;
}
.industries p{
   text-align: left;
  margin-left: 70px;
  font-size: 18px;
}
.row {
  display: flex;
}

/* Create two equal columns that sits next to each other */
.column {
  flex: 50%;
  height: 300px;
}
.column1 {
  flex: 30%;
  height: 500px;
}
.column2 {
  flex: 60%;
  height: 500px;
}
.column3 {
  flex: 10%;
  height: 500px;
}

.containernew{
  margin-top: 130px;
  background-color: #D52D34;
  height: 120px;
  width: 1108px;
  z-index: 6;
  position: absolute;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}
.grid-child img{
  width:40px;
  height:40px;
}
.grid-child p{
  color:white;
}
.grid-child {
  text-align: center;
  margin-top: 20px;
  line-height: 25px;
}

@media screen and (max-width: 640px) {
  .containernew {
    height: 560px;
    width: 230px;
}
.grid-container {
  display: block;
 
}
.og-footer {
  margin-top: 100px;
  background-color: #000000;
  padding-top: 40px;
}
.header h1 {
  margin-top: 80px;
}
.client {
  margin-left: 80px;
}
.client img {
  margin-bottom: 25px;
}
.industries h1 {
  text-align: center;
 margin-left: 0px;
   margin-top: 0px; 
}
.industries p {
  text-align: center;
  margin-left: 0px;
}
.column {
  height: 333px;
}
}