.service{
    margin-left: 80px;
}
.serviceaboutnew2 img{
    float: right;
}
.servicenew1 h3{
    font-size: 40px;
    font-weight: 600;
    margin-top: 50px;
}
.servicenew1 h4{
    font-weight: 400;
    margin-top: 30px;
}
.serviceheader{
    margin-top: 60px;
}
.serviceheader p{
    font-weight: 600;
    margin-top: 25px;
}
.service1{
    margin-top: 80px;
    
}
.servicesection2 img{
    width: 100%;
    height: 550px;
}
.servicesection1 h3{
    margin-top: 80px;
    margin-left: 80px;
    font-weight: 700;
}
.servicesection1 p{
    margin-top: 30px;
    margin-left: 80px;
}
.serviceheader1 p{
    font-weight: 800;
    margin-left: 80px;
}
.servicesection3 img{
    width: 104%;
    height: 550px;
}
.servicesection4 h3{
    margin-top: 80px;
    margin-left: 50px;
    font-weight: 700;
    margin-right: 10px;
}
.servicesection4 p{
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 10px;
}
.serviceheader4 p{
    font-weight: 800;
    margin-left: 50px;
    margin-right: 10px;
}
.serviceheader2 p{
    font-weight: 800;
}
.serviceheader1{
    width: 80%;
}

.expertise{
    height: 200px;
    background-color: #D52D34;
    color: #ffffff;
}
.expertise h1{
    text-align: center;
    font-weight: 700;
    padding-top: 80px;
}
.expertisecontent{
    margin-left: 90px;
    margin-right: 90px;
    margin-top: 50px;
  }
  .card-body img{
    width:50px;
    height:50px;
  }
  .card-body p{
    text-align: left;
    padding-bottom: 20px;
  }
  
  .technologies{
    height: 200px;
    background-color: #D52D34;
    color: #ffffff;
}
.technologies h1{
    text-align: center;
    font-weight: 700;
    padding-top: 80px;
} 
.design h2{
    margin-left: 90px;
    margin-top: 50px;
    font-weight: 700;
  
}
.design img{
    width: 70px;
}
.designelements img{
    margin-left: 140px;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    
} 
/* .element1{
    background-color: rgba(213, 45, 52, 0.04)
}
.element2{
    background-color: rgba(213, 45, 52, 0.04)
} */
.designelements{
    margin-top: 50px;
    margin-left: 80px;
    margin-right: 80px;
   
}

.frontend h2{
    margin-left: 90px;
    margin-top: 50px;
    font-weight: 700;
  
}
.frontend img{
    width: 70px;
}
.frontendelements img{
    margin-left: 140px;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    
} 
.frontendelements{
    margin-top: 50px;
    margin-left: 80px;
    margin-right: 80px;
   
}
.backend h2{
    margin-left: 90px;
    margin-top: 50px;
    font-weight: 700;
  
}
.backend img{
    width: 70px;
}

.backendelements img{
    margin-left: 140px;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    
} 
.backendelements{
    margin-top: 50px;
    margin-left: 80px;
    margin-right: 80px;
   
}
.mobile h2{
    margin-left: 90px;
    margin-top: 50px;
    font-weight: 700;
  
}
.mobile img{
    width: 70px;
}

.mobileelements img{
    margin-left: 140px;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    
} 
.mobileelements{
    margin-top: 50px;
    margin-left: 80px;
    margin-right: 80px;
   
}
.server h2{
    margin-left: 90px;
    margin-top: 50px;
    font-weight: 700;
  
}
.server img{
    width: 70px;
}

.serverelements img{
    margin-left: 140px;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    
} 
.serverelements{
    margin-top: 50px;
    margin-left: 80px;
    margin-right: 80px;
   
}
.database h2{
    margin-left: 90px;
    margin-top: 50px;
    font-weight: 700;
  
}
.database img{
    width: 70px;
}

.databaseelements img{
    margin-left: 140px;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    
} 
.databaseelements{
    margin-top: 50px;
    margin-left: 80px;
    margin-right: 80px;
   
}

.ecommerce h2{
    margin-left: 90px;
    margin-top: 50px;
    font-weight: 700;
  
}
.ecommerce img{
    width: 70px;
}

.ecommerceelements img{
    margin-left: 140px;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    
} 
.ecommerceelements{
    margin-top: 50px;
    margin-left: 80px;
    margin-right: 80px;
   
}
.project h2{
    margin-left: 90px;
    margin-top: 50px;
    font-weight: 700;
  
}
.project img{
    width: 70px;
}

.projectelements img{
    margin-left: 140px;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    
} 
.projectelements{
    margin-top: 50px;
    margin-left: 80px;
    margin-right: 80px;
   
}
.devops h2{
    margin-left: 90px;
    margin-top: 50px;
    font-weight: 700;
  
}
.devops img{
    width: 70px;
}

.devopselements img{
    margin-left: 140px;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    
} 
.devopselements{
    margin-top: 50px;
    margin-left: 80px;
    margin-right: 80px;
   
}
.cms h2{
    margin-left: 90px;
    margin-top: 50px;
    font-weight: 700;
  
}
.cms img{
    width: 70px;
}

.cmselements img{
    margin-left: 140px;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    
} 
.cmselements{
    margin-top: 50px;
    margin-left: 80px;
    margin-right: 80px;
   
}
.version h2{
    margin-left: 90px;
    margin-top: 50px;
    font-weight: 700;
  
}
.version img{
    width: 70px;
}

.versionelements img{
    margin-left: 140px;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    
} 
.versionelements{
    margin-top: 50px;
    margin-left: 80px;
    margin-right: 80px;
   
}
.other h2{
    margin-left: 90px;
    margin-top: 50px;
    font-weight: 700;
  
}
.other img{
    width: 70px;
}

.otherelements img{
    margin-left: 140px;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    
} 
.otherelements{
    margin-top: 50px;
    margin-left: 80px;
    margin-right: 80px;
   
} 
.communication h2{
    margin-left: 90px;
    margin-top: 50px;
    font-weight: 700;
  
}
.communication img{
    width: 70px;
}

.communicationelements img{
    margin-left: 140px;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    
} 
.communicationelements{
    margin-top: 50px;
    margin-left: 80px;
    margin-right: 80px;
   
} 
.meeting h2{
    margin-left: 90px;
    margin-top: 50px;
    font-weight: 700;
  
}
.meeting img{
    width: 70px;
}

.meetingelements img{
    margin-left: 140px;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    
} 
.meetingelements{
    margin-top: 50px;
    margin-left: 80px;
    margin-right: 80px;
   
} 

@media screen and (max-width: 640px) {
    .service {
        margin-left: 55px;
    }
    .expertisecontent {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 50px;
    }   
    .servicesection1 h3 {
        margin-top: 0px;
        margin-left: 0px;
        text-align: center;
    }
    .servicesection1 p {
        margin-top: 20px;
        margin-left: 0px;
        text-align: center;
    }
    .serviceheader1 {
        width: 100%;
    }
    .servicesection4 h3 {
        margin-top: 30px;
        margin-left: 0px;
        text-align: center;
    }
    .servicesection4 p {
        margin-top: 20px;
        margin-left: 0px;
        text-align: center;
    }
    .designelements {
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .design h2 {
        margin-left: 40px;
    }
    .designelements img {
        margin-left: 85px;
    }
    .frontend h2 {
        margin-left: 40px;
    } 
    .frontendelements img{
        margin-left: 85px;
    }
    .frontendelements {
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .backend h2{
        margin-left: 40px;
    }
    .backendelements img{
        margin-left: 85px;
    }
    .backendelements {
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .mobile h2{
        margin-left: 40px;
    }
    .mobileelements img{
        margin-left: 85px;
    }
    .mobileelements {
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .server h2{
        margin-left: 40px;
    }
    .serverelements img{
        margin-left: 85px;
    }
    .serverelements {
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .database h2{
        margin-left: 40px;
    }
    .databaseelements img{
        margin-left: 85px;
    }
    .databaseelements {
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .ecommerce h2{
        margin-left: 40px;
    }
    .ecommerceelements img{
        margin-left: 85px;
    }
    .ecommerceelements {
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .project h2{
        margin-left: 40px;
    }
    .projectelements img{
        margin-left: 85px;
    }
    .projectelements {
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .devops h2{
        margin-left: 40px;
    }
    .devopselements img{
        margin-left: 85px;
    }
    .devopselements {
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .cms h2{
        margin-left: 40px;
    }
    .cmselements img{
        margin-left: 85px;
    }
    .cmselements {
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .version h2{
        margin-left: 40px;
    }
    .versionelements img{
        margin-left: 85px;
    }
    .versionelements {
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .other h2{
        margin-left: 40px;
    }
    .otherelements img{
        margin-left: 85px;
    }
    .otherelements {
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .communication h2{
        margin-left: 40px;
    }
    .communicationelements img{
        margin-left: 85px;
    }
    .communicationelements {
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .meeting h2{
        margin-left: 40px;
    }
    .meetingelements img{
        margin-left: 85px;
    }
    .meetingelements {
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .serviceaboutnew2 img {
       height: 300px;
    }
}